import { Organization } from '@src/Infrastructure/Types/OrganizationType'

export const STRAPI_COMPONENT_TYPES = {
    HERO_SECTION: 'pages.hero-section',
    HERO_LANDING_VIEW: 'pages.hero-landing-view',
    USP_SECTION: 'pages.usp-component',
    SERVICES_SECTION: 'pages.services-section',
    AGILITY_SECTION: 'pages.agility-section',
    ADDITIONAL_SERVICES: 'pages.additional-service',
    CONTACT_US: 'pages.contact-us',
    APP_SCREENSHOTS: 'pages.app-screenshots',
    COMPANY_INFO: 'pages.company-info',
    BLOG: 'pages.blog-component',
    TESTIMONIAL: 'pages.testimonial-component',
    TOOLS: 'pages.tools-component',
    ICON_TITLE: 'pages.icon-title',
    LOGOS_SECTION: 'pages.logos-component',
    ROUTES_SECTION: 'pages.routes-section',
    SOCIAL_SECTION: 'pages.social-feed-section',
    CONTACT_FORM: 'pages.contact-form',
    OFFICES_SECTION: 'pages.our-offices-component',
    OUR_STORY: 'pages.our-story-component',
    OUR_TEAM: 'pages.our-team',
    OUR_VALUE_PROPOSITION: 'pages.our-value-proposition',
    TITLE_DESCRIPTION_BULLETS: 'pages.title-description-bullets',
    TITLE_DESCRIPTION: 'pages.title-description',
    IMAGE_SLIDER: 'pages.image-slider',
    TITLE_DESCRIPTION_ACCORDION: 'pages.title-description-accordion',
    GRAPH: 'pages.graph',
    GRAPH_BAR: 'pages.graph-bar',
    GRAPH_PIE: 'pages.graph-pie',
    CBM_CALCULATOR: 'pages.cbm-calculator',
    HORIZONTAL_LINKS: 'pages.horizontal-links',
    VERTICAL_LINKS: 'pages.vertical-links',
    TABLE: 'pages.table',
    BLOG_LIST: 'pages.blog-list',
    SINGLE_TITLE_DESCRIPTION_CTA: 'pages.single-title-description-cta',
    BLOG_CONTENT: 'blogs.content',
    BLOG_BUTTON: 'blogs.button',
    BLOG_DETAILS: 'blogs.details',
    TITLE_DESCRIPTION_CARDS: 'pages.title-description-cards',
    IMAGE_TITLE_DETAIL_CARDS: 'pages.image-title-detail-cards',
    HELP_CENTER_LIST: 'pages.help-center-list',
    TITLE_DESCRIPTION_MAP: 'pages.title-description-map',
    TRADELANES: 'pages.tradelanes',
    GLOBAL_FREIGHT_GUIDE_LIST: 'pages.global-freight-guide-list',
    FREIGHT_RESOURCES_LIST: 'pages.freight-resources-list',
    NOTE_ALERT: 'pages.note-alert',
    VIDEO: 'pages.video',
    BREADCRUMB: 'breadcrumb',
    HERO_PROFILE: 'pages.hero-profile-component',
    TITLE: 'title',
    SITEMAP_LIST: 'sitemap-list',
}

export const BLOG_PAGINATION_LIMIT = 20

export const ARTICLE_SECTION_HEADING_REGEXP = /<h2[^>]*>(.*?)<\/h2>/g
export const ARTICLE_SECTION_SUBMENU_REGEXP = /<h3[^>]*>(.*?)<\/h3>/g

export const NUMBERED_LIST_REGEXP = /^\d+\./g
export const START_WHITESPACE_REGEXP = /^\s+/g

export const STRIP_HTML_TAGS = /(<([^>]+)>)/gi

export const SIDEBAR_POSITION = {
    BOTTOM: 'bottom',
    FIXED: 'fixed',
}

export const MAP_ORGANIZATION_WITH_LANGUAGE: Record<Organization, string[]> = {
    icontainers: ['en', 'es', 'de', 'nl'],
    boxit4me: ['en', 'ar'],
}

export const MAP_ORGANIZATION_WITH_SITEMAP_PAGES: Record<Organization, { locale: string; pageUrl: string }[]> = {
    icontainers: [
        {
            locale: 'en',
            pageUrl: '/sitemap/',
        },
        {
            locale: 'es',
            pageUrl: '/sitemap/',
        },
        {
            locale: 'de',
            pageUrl: '/sitemap/',
        },
        {
            locale: 'nl',
            pageUrl: '/sitemap/',
        },
    ],
    boxit4me: [
        {
            locale: 'en',
            pageUrl: '/sitemap/',
        },
        {
            locale: 'ar',
            pageUrl: '/sitemap/',
        },
    ],
}

export const BLOG_DETAILS_INNER_COMPONENTS = [
    STRAPI_COMPONENT_TYPES.BLOG_CONTENT,
    STRAPI_COMPONENT_TYPES.BLOG_BUTTON,
    STRAPI_COMPONENT_TYPES.CBM_CALCULATOR,
    STRAPI_COMPONENT_TYPES.NOTE_ALERT,
    STRAPI_COMPONENT_TYPES.TITLE_DESCRIPTION_ACCORDION,
]
